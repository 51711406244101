import React from "react";
import { graphql } from 'gatsby'
import AOS from 'aos';

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';

export default function TermsConditions({data}) {
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return (
    <PrimaryLayout>
      <SEO 
        title={data.wordpressPage.acf.seo_title}
        description={data.wordpressPage.acf.seo_description}
        keywords={data.wordpressPage.acf.seo_keywords}
      />
      <div class="container-fluid">
        <div class="row p-0 m-0">
          <div class="container main-container-padding-t-5 main-container-padding-b-2">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="header-text-container"
                  data-aos="fade-up"
                >
                  <div class="vertical-center">
                    <h1
                      data-aos="fade-up"
                      dangerouslySetInnerHTML={{__html: data.wordpressPage.title}}
                     />
                  </div>
                </div>
              </div>
              <div
                class="col-md-6"
                data-aos="fade"
              >
                <img
                  data-src="https://storage.googleapis.com/alkye.com/1/2020/06/blog-1.jpg"
                  class="header-image lazyload"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <article
        id="post-243"
        class="post-243 page type-page status-publish hentry entry"
      >
        <div class="container-fluid primary-color-bg  ">
          <div class="container p-2">
            <div
              class="row main-container-padding-b-1 main-container-padding-t-1"
              data-aos="fade-up"
            >
              <div class="col-md-12" dangerouslySetInnerHTML={{__html: data.wordpressPage.content}}></div>
            </div>
            <hr />
          </div>
        </div>
      </article>
    </PrimaryLayout>
  );
}


export const query = graphql`
{
  wordpressPage(slug: {eq: "terms-conditions"}) {
    content
    title
    acf{
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`